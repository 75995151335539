import { useNavigate, useSearchParams } from '@remix-run/react';
import { useState } from 'react';
import { useDebounce } from 'react-use';

import {
  type DtoPage,
  type DtoPageSection,
} from '@lp-lib/api-service-client/public';

import { type AppAnalytics } from '../../../src/analytics/app/shared';
import { SearchIcon } from '../../../src/components/icons/SearchIcon';
import { PageSectionNav } from '../DynamicPage/PageSectionNav';

export function PublicLibraryNavSearchBar(props: {
  targetPath: string;
  analytics?: AppAnalytics;
}) {
  const [params] = useSearchParams();
  const q = params.get('q');
  const [value, setValue] = useState(q);
  const navigate = useNavigate();

  const handleValueChange = () => {
    if (value === null) return;

    const trimmed = value.trim();
    if (trimmed === q?.trim()) return;

    if (props.analytics) {
      props.analytics.trackSearchQueried(trimmed);
    }
    const nextParams = new URLSearchParams(params);
    nextParams.set('q', trimmed);
    navigate({
      pathname: props.targetPath,
      search: nextParams.toString(),
    });
  };

  useDebounce(handleValueChange, 500, [value]);

  return (
    <label
      className='
        w-45 h-10 rounded-xl bg-black border border-secondary
        text-sms text-white
        flex justify-between items-center transition-colors
      '
    >
      <div className='flex-1 h-full'>
        <input
          className={`w-full h-full px-2.5 overflow-hidden bg-transparent outline-none`}
          value={value || ''}
          onChange={(event) => setValue(event.target.value)}
          autoFocus={!!q}
          placeholder='Search for an event'
        />
      </div>

      <div
        className={`mr-1 flex-none w-7.5 h-7.5 bg-layer-002 rounded-lg flex items-center justify-center text-secondary`}
      >
        <SearchIcon className='w-3.5 h-3.5 fill-current' />
      </div>
    </label>
  );
}

export function PublicLibraryNav(props: {
  page: DtoPage;
  isSectionSelected?: (section: DtoPageSection) => boolean;
  onSectionClick: (section: DtoPageSection, index: number) => void;
  actions?: React.ReactNode;
  className?: string;
}) {
  const { page, isSectionSelected, onSectionClick, actions } = props;

  const showNav =
    page.sections &&
    page.sections.filter((section) => !section.hideFromNav).length > 1;
  if (!showNav && !actions) return null;
  return (
    <div
      className='
        w-full h-15 flex-none
        bg-modal text-white border-b border-secondary  
        flex items-center justify-center
      '
    >
      <div
        className={`w-full h-full flex justify-between items-center gap-10 ${props.className}`}
      >
        <div className='flex-1 h-full overflow-hidden'>
          {showNav && (
            <PageSectionNav
              sections={page.sections || []}
              onSectionClick={onSectionClick}
              selectedSection={
                isSectionSelected
                  ? page?.sections?.find(isSectionSelected)
                  : null
              }
            />
          )}
        </div>

        {actions}
      </div>
    </div>
  );
}
